module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PT","short_name":"PT","start_url":"https://primortaxi.com","scope":"https://primortaxi.com","icon":"src/images/favicon.png","legacy":true,"display":"browser","cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"d41d8cd98f00b204e9800998ecf8427e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
